import Rails from '@rails/ujs';
import { iti } from './tel_input';
import { displaySpinner, hideSpinner } from './packs/application';

document.addEventListener("turbo:load", () => {
  const mobile_money_form = document.querySelector('#charge-mobile-money-form');
  const credit_card_form = document.querySelector('#charge-credit-card-form');

  if (mobile_money_form) {
    const operatorSelect = document.querySelector('#mobile-money-operator');
    if (operatorSelect) {
      operatorSelect.addEventListener('change', () => {
        const selectedOperator = operatorSelect.value;
        const operatorMapping = getOperatorMapping();
        updateFlagByOperator(selectedOperator, operatorMapping);
      });
    }

    initializeMobileMoneyForm();
  }

  if (credit_card_form) {
    const cardElementContainer = document.getElementById('card-element');
    if (cardElementContainer) {
      initializeStripeElements();
    }
  }
});

function initializeStripeElements() {
  const form = document.querySelector('#charge-credit-card-form');
  if (form) {
    const stripePublishableKey = document.querySelector("meta[name='stripe-publishable-key']").content;
    const stripe = Stripe(stripePublishableKey);

    const elements = stripe.elements({ locale: 'fr' });
    const cardElement = elements.create('card', { hidePostalCode: true });
    cardElement.mount('#card-element');

    cardElement.addEventListener('change', (event) => {
      const displayError = form.querySelector('#card-errors');
      if (event.error) {
        displayError.textContent = event.error.message;
      } else {
        displayError.textContent = '';
      }
    });

    // Add event listener to form submit button
    const submitButton = form.querySelector('#credit-card-submit-button');
    const spinner = submitButton.querySelector('.spinner-border');
    form.addEventListener('submit', async (event) => {
      event.preventDefault();
      if (spinner) {
        displaySpinner(spinner);
      }
      submitButton.disabled = true;

      const cardHolderName = form.querySelector('#card-holder-name').value;
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
        billing_details: {
          name: cardHolderName,
        },
      });

      if (error) {
        flashAlert('Erreur dans le mode de paiement : ' + error);
        if (spinner) {
          hideSpinner(spinner);
        }
        submitButton.disabled = false;
      } else {
        const orderId = $('input[name="order-id"]').val();
        fetch('/orders/confirm_payment', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector("meta[name='csrf-token']").content,
          },
          body: JSON.stringify({
            payment_method_id: paymentMethod.id,
            order_id: orderId,
            payment_method: "credit-card"
          }),
        })
          .then((response) => response.json())
          .then((result) => {
            if (result.error) {
              if (spinner) {
                hideSpinner(spinner);
              }
              submitButton.disabled = false;
              flashAlert('Erreur de confirmation de paiement : ' + result.error);
            } else {
              if (spinner) {
                hideSpinner(spinner);
              }
              submitButton.disabled = false;
              Turbo.visit(result.redirectionUrl);
            }
          });
      }
    });
  }
}

function initializeMobileMoneyForm() {
  const form = document.querySelector('#charge-mobile-money-form');
  if (!form) return;

  const submitButton = form.querySelector('#mobile-money-submit-button');
  const spinner = submitButton.querySelector('.spinner-border');

  form.addEventListener('submit', async (event) => {
    event.preventDefault();
    if (spinner) displaySpinner(spinner);
    submitButton.disabled = true;

    const orderId = form.querySelector('input[name="order-id"]').value;
    const operator = form.querySelector('#mobile-money-operator').value;
    const fullPhoneNumber = form.querySelector('#mobile_money_phone').value;
    const operatorMapping = getOperatorMapping();
    const phoneNumber = getPhoneNumberWithoutPrefix(operator, fullPhoneNumber, operatorMapping);
    const { countryCode } = operatorMapping[operator];
    const paymentGateway = choosePaymentGateway(form, countryCode);
    const productId = form.querySelector('input[name="product-id"]').value;
    const productType = form.querySelector('input[name="product-type"]').value;
    const orderData = {
      order_id: orderId,
      operator: operator,
      phone_number: phoneNumber,
      country_code: countryCode,
      payment_method: 'mobile-money',
    };

    switch (paymentGateway) {
      case 'kkiapay':
        handleKkiapayPayment(orderData, productId, productType);
        break;
      case 'fedapay':
        handleMobileMoneyWidgetPayment(orderData, productId, productType, paymentGateway);
        break;
      default:
        handleMobileMoneyPayment(orderData);
    }
  });
}

function handleKkiapayPayment(orderData, productId, productType) {
  const kkiapayPublicKey = document.querySelector("meta[name='kkiapay-public-key']").content;

  openKkiapayWidget({
    amount: parseInt(result.amount),
    fullname: result.fullname,
    email: result.email,
    key: kkiapayPublicKey,
    sandbox: result.sandbox,
    phone: orderData.phone_number,
    callback: result.redirectUrl,
    data: {
      order_id: result.orderId,
      product_id: productId,
      product_type: productType,
    },
  });
}

function handleFedapayPayment(result) {
  const fedapayPublicKey = document.querySelector("meta[name='fedapay-public-key']").content;

  if (!window.FedaPay) return;

  const fedapay_widget = FedaPay.init({
    public_key: fedapayPublicKey,
    transaction: {
      id: result.transactionId,
    }
  });

  fedapay_widget.open();
}

function handleMobileMoneyWidgetPayment(orderData, productId, productType, paymentGateway) {
  const form = document.querySelector('#charge-mobile-money-form');
  if (!form) return {};

  const submitButton = form.querySelector('#mobile-money-submit-button');
  const spinner = submitButton.querySelector('.spinner-border');

  Rails.ajax({
    type: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken(),
    },
    url: '/orders/confirm_payment',
    data: JSON.stringify(orderData),
    success: (result) => {
      if (result.status === 'error') {
        flashAlert('Erreur de confirmation de paiement : ' + result.error);
        if (spinner) hideSpinner(spinner);
        submitButton.disabled = false;
      } else {
        switch (paymentGateway) {
          case 'fedapay':
            handleFedapayPayment(result);
            break;
          case 'kkiapay':
            handleKkiapayPayment(result, orderData, productId, productType);
            break;
        }
      }
    },
    error: (result) => flashAlert(result.notice),
  });
}

function handleMobileMoneyPayment(orderData) {
  const form = document.querySelector('#charge-mobile-money-form');
  if (!form) return;

  const submitButton = form.querySelector('#mobile-money-submit-button');
  const spinner = submitButton.querySelector('.spinner-border');

  Rails.ajax({
    type: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken(),
    },
    url: `/orders/confirm_payment`,
    data: JSON.stringify(orderData),
    success: (result) => {
      if (result.status == 'success') {
        if (spinner) hideSpinner(spinner);
        submitButton.disabled = false;
        Turbo.visit(result.redirectionUrl);
      } else if (result.status == 'pending') {
        if (result.redirectUrl) {
          window.location = result.redirectUrl;
        } else {
          checkOrderStatus(result.orderId);
        }
      } else {
        flashAlert('Erreur de confirmation de paiement : ' + result.error);
        if (spinner) hideSpinner(spinner);
        submitButton.disabled = false;
      }
    },
    error: (result) => {
      flashAlert('Erreur lors de la demande de paiement : ' + result.notice);
      if (spinner) hideSpinner(spinner);
      submitButton.disabled = false;
    },
  });
}

function getOperatorMapping() {
  const form = document.querySelector('#charge-mobile-money-form');
  if (!form) return {};

  const operatorMappingData = JSON.parse(form.dataset.operatorMapping);
  const countryPrefixMapping = JSON.parse(form.dataset.countryPrefixMapping);

  const operatorMapping = {};
  for (const [countryCode, operators] of Object.entries(operatorMappingData)) {
    const prefix = countryPrefixMapping[countryCode];
    operators.forEach(operator => {
      const operatorKey = `${operator.toLowerCase()}_${countryCode.toLowerCase()}`;
      operatorMapping[operatorKey] = { countryCode, prefix };
    });
  }

  return operatorMapping;
}

function updateFlagByOperator(operator, operatorMapping) {
  const countryCode = operatorMapping[operator].countryCode;
  iti.setCountry(countryCode);
}

function getPhoneNumberWithoutPrefix(operator, phoneNumber, operatorMapping) {
  const { prefix } = operatorMapping[operator];

  if (phoneNumber.startsWith(prefix)) {
    return phoneNumber.slice(prefix.length);
  }

  const submitButton = document.querySelector('#mobile-money-submit-button');
  const spinner = submitButton.querySelector('.spinner-border');

  submitButton.disabled = false;
  hideSpinner(spinner);
  flashAlert("Il y a un problème avec ton numéro de téléphone. Vérifie qu'il commence par le bon préfixe : " + prefix);
  throw new Error(`Le numéro de téléphone ne commence pas par le préfixe attendu : ${prefix}`);
}

export function flashAlert(message) {
  let container = document.getElementById('inscription-program-flash-alerts');

  let alertBox = document.createElement('div');
  alertBox.className = 'alert alert-danger rounded-0 text-center alert-dismissible fade show';
  alertBox.role = 'alert';
  alertBox.innerHTML = `
    ${message}
    <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
  `;

  container.appendChild(alertBox);
}

var checkInterval = 5000;

function checkOrderStatus(orderId) {
  const form = document.querySelector('#charge-mobile-money-form');
  if (!form) return;

  const submitButton = form.querySelector('#mobile-money-submit-button');
  const spinner = submitButton.querySelector('.spinner-border');

  Rails.ajax({
    type: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': Rails.csrfToken()
    },
    url: `/orders/check_order_status`,
    data: JSON.stringify({
      order_id: orderId
    }),
    success: (result) => {
      if (result.status === 'success') {
        if (spinner) {
          hideSpinner(spinner);
        }
        submitButton.disabled = false;
        Turbo.visit(result.redirectionUrl);
      } else if (result.status === 'pending') {
        setTimeout(() => checkOrderStatus(orderId), checkInterval);
      } else {
        flashAlert('Erreur de confirmation de paiement : ' + result.error);
        if (spinner) {
          hideSpinner(spinner);
        }
        submitButton.disabled = false;
      }
    },
    error: (result) => {
      flashAlert('Erreur dans la vérification du statut de la commande : ' + result.notice);
      if (spinner) {
        hideSpinner(spinner);
      }
      submitButton.disabled = false;
    }
  });
}

function choosePaymentGateway(form, countryCode) {
  const pawapaySupportedCountries = JSON.parse(form.dataset.pawapaySupportedCountries);
  const kkiapaySupportedCountries = JSON.parse(form.dataset.kkiapaySupportedCountries);
  const nokashSupportedCountries = JSON.parse(form.dataset.nokashSupportedCountries);

  if (pawapaySupportedCountries.includes(countryCode)) {
    return 'pawapay';
  } else if (kkiapaySupportedCountries.includes(countryCode)) {
    return 'kkiapay';
  } else if (nokashSupportedCountries.includes(countryCode)) {
    return 'nokash';
  } else {
    return 'fedapay';
  }
}
